import React from 'react';
import { Card, Icon, Image } from 'semantic-ui-react';
const imagePath = process.env.PUBLIC_URL + '/assets/team_images/';

const Member = ({ member }) => {
    return (
        <Card>
            <Image alt={member.name} src={`${imagePath}${member.avatar}`} size="medium" />
            <Card.Content>
                <Card.Header>{member.name}</Card.Header>
                <Card.Meta>
                    <span className="date" dangerouslySetInnerHTML={{ __html: member.title }} />
                </Card.Meta>
                {member.twitter === 'mlabriola' && (
                    <Card.Description>
                        <small style={{ lineHeight: 0.5 }}>
                            Actual organizer not depicted in photo & this website is not food, do not attempt to eat
                            this website.
                        </small>
                    </Card.Description>
                )}
            </Card.Content>
            {(member.github || member.twitter) && (
                <Card.Content extra textAlign="center">
                    {member.github && (
                        <a rel="noopener noreferrer" target="_blank" href={`https://www.github.com/${member.github}`}>
                            <Icon size="large" name="github" />
                        </a>
                    )}
                    {member.twitter && (
                        <a rel="noopener noreferrer" target="_blank" href={`https://www.twitter.com/${member.twitter}`}>
                            <Icon size="large" name="twitter" />
                        </a>
                    )}
                </Card.Content>
            )}
        </Card>
    );
};

export default Member;
