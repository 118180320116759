import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Container, Image, Header } from 'semantic-ui-react';
import PageHeader from '../common-page/PageHeader';
import shuffle from '../../utils/shuffle';
import './Sponsors.css';

const logoPath = process.env.PUBLIC_URL + '/assets/sponsor_logos/';
const logoSizes = {
    premier: 500,
    platinum: 450,
    gold: 350,
    silver: 300,
    partner: 200
};

const Sponsors = ({ config }) => {
    const { sponsors, site } = config;
    if (!site.available.sponsors) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <PageHeader
                className="sponsors"
                headline="We ❤️ our sponsors. They're pretty great."
                subline="Get your organization involved."
                config={config}
            />
            <Container>
                {/* <p style={{ textAlign: 'center', maxWidth: 768, margin: '0 auto' }}>
                    If you're interested in how your organization can support React Loop 2019 please review our{' '}
                    <a href="/docs/ReactLoop-Sponsorship-2019.pdf" target="_blank">
                        Sponsor Prospectus
                    </a>{' '}
                    and reach out to us at <a href="mailto:sponsor@reactloop.com">sponsor@reactloop.com</a>.
                </p> */}
                {!site.available.sponsorPitchOnly ? (
                    Object.keys(sponsors)
                        .filter(l => sponsors[l].some(s => s.active))
                        .map((level, levelIndex) => {
                            return (
                                <div key={level}>
                                    <Container className="sponsor-level-container">
                                        {shuffle(sponsors[level])
                                            .filter(s => s.active)
                                            .map(({ url, name, image, w, h }, sponsorIndex) => (
                                                <a
                                                    href={url}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    key={`${levelIndex}-${sponsorIndex}`}>
                                                    <div className="sponsor-link">
                                                        <Image
                                                            alt={name}
                                                            style={{
                                                                width: w || logoSizes[level],
                                                                height: h || logoSizes[level]
                                                            }}
                                                            centered
                                                            src={`${logoPath}${image}`}
                                                        />
                                                        {name && <b>{name}</b>}
                                                    </div>
                                                </a>
                                            ))}
                                    </Container>
                                </div>
                            );
                        })
                ) : (
                    <div style={{ marginBottom: 450 }}>
                        <Header as="h2">Sponsor Info</Header>
                        <p>
                            We are excited to have your organization involved with React Loop. React Loop is a one day
                            community conference focused on topics related to the React.js framework. Sponsoring React
                            Loop is a great way to reach skilled and passionate developers who are building front-end
                            applications with React, iOS and Android projects with React Native, GraphQL, Universal
                            Javascript on the server, and much more.
                        </p>
                        <p>
                            Please reach out to us at <a href="mailto:sponsor@reactloop.com">sponsor@reactloop.com</a>{' '}
                            to see how your organization can be involved in the conference.
                        </p>
                        <p>
                            React Loop has a <Link to="/conduct">code of conduct</Link> to which all participants and
                            sponsors are held accountable.
                        </p>
                    </div>
                )}
            </Container>
        </>
    );
};

export default Sponsors;
