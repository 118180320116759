import React from 'react';
import moment from 'moment';
import { Card, Button } from 'semantic-ui-react';

const TicketGroup = ({ link, buy = false, ticketGroup, date, add = 0 }) => {
    const dateAvailable = moment
        .utc(date)
        .add(add, 'days')
        .format('MMMM DD, YYYY');

    const { soldOut, name, price, description } = ticketGroup;

    const canBuy = buy;

    return (
        <Card>
            <Card.Content>
                <Card.Header>{name}</Card.Header>
                <Card.Meta>{`$${price}`}</Card.Meta>
                <Card.Description style={{ height: 40 }}>{description}</Card.Description>
            </Card.Content>
            <Card.Content>
                {canBuy ? (
                    <Button
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link}
                        size="large"
                        color={soldOut ? 'grey' : 'green'}
                        disabled={soldOut}>
                        {soldOut ? 'Sold out' : 'Buy Now'}
                    </Button>
                ) : (
                    <span>Available: {dateAvailable}</span>
                )}
            </Card.Content>
        </Card>
    );
};

export default TicketGroup;
