import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Image, Container, Icon, Menu, Responsive, Sidebar } from 'semantic-ui-react';
import logo from './logo.png';

class MobileContainer extends React.Component {
    state = { sidebarOpened: false };
    hideAndScroll = () => {
        this.hide();
    };
    hide = () => this.setState({ sidebarOpened: false });
    handleToggle = () => this.setState({ sidebarOpened: true });
    componentDidMount() {
        document.addEventListener('scroll', this.hide);
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.hide);
    }
    addDimmerTouch = () => {
        document.querySelector('.pusher.dimmed').addEventListener('touchend', this.hide);
    };

    render() {
        const { config, children } = this.props;
        const { TICKET_SALES } = config.links;
        const { videos, speakers, sponsors, tickets, schedule, venue, about, soldOut } = config.site.available;
        const { sidebarOpened } = this.state;

        return (
            <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
                <Sidebar
                    className="mobile-nav"
                    as={Menu}
                    animation="overlay"
                    onHide={this.hide}
                    vertical
                    fixed="top"
                    direction="right"
                    onShow={this.addDimmerTouch}
                    visible={sidebarOpened}>
                    <Menu.Menu position="right">
                        <Menu.Item as={Link} onClick={this.hide} to="/">
                            Home
                        </Menu.Item>
                        {tickets && !soldOut && (
                            <Menu.Item as="a" target="_blank" rel="noopener noreferrer" href={TICKET_SALES}>
                                Buy Tickets
                            </Menu.Item>
                        )}
                        {videos && (
                            <Menu.Item as={Link} onClick={this.hide} to="/videos">
                                Videos
                            </Menu.Item>
                        )}
                        {speakers && (
                            <Menu.Item as={Link} onClick={this.hide} to="/speakers">
                                Speakers
                            </Menu.Item>
                        )}
                        {schedule && (
                            <Menu.Item as={Link} onClick={this.hide} to="/schedule">
                                Schedule
                            </Menu.Item>
                        )}
                        {venue && (
                            <Menu.Item as={Link} onClick={this.hide} to="/venue">
                                Venue
                            </Menu.Item>
                        )}
                        {sponsors && (
                            <Menu.Item as={Link} onClick={this.hide} to="/sponsors">
                                Sponsors
                            </Menu.Item>
                        )}
                        {about && (
                            <Menu.Item as={Link} onClick={this.hide} to="/about">
                                About
                            </Menu.Item>
                        )}
                        <Menu.Item as={Link} onClick={this.hide} to="/conduct">
                            Conduct
                        </Menu.Item>
                        {tickets && !soldOut && (
                            <Menu.Item as="a" target="_blank" rel="noopener noreferrer" href={TICKET_SALES}>
                                Buy Tickets
                            </Menu.Item>
                        )}
                    </Menu.Menu>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Container fluid>
                        <Menu pointing size="large" fixed="top">
                            <Menu.Item as={Link} to="/" style={{ border: 0 }} className="borderless no-hover">
                                <Image
                                    src={logo}
                                    alt="React Loop - The First Chicago ReactJS Conference"
                                    size="small"
                                />
                            </Menu.Item>
                            <Menu.Item position="right" onClick={this.handleToggle}>
                                <Icon name="sidebar" />
                            </Menu.Item>
                        </Menu>
                    </Container>
                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node
};

export default withRouter(MobileContainer);
