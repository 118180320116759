import React from 'react';
import { Card, Icon, Image } from 'semantic-ui-react';
const imagePath = process.env.PUBLIC_URL + '/assets/speaker_images/';

const Speaker = ({ speaker, featured }) => {
    
        return (
            <Card>
                <Image alt={speaker.name} src={`${imagePath}${speaker.avatar}`} size="medium" />
                <Card.Content>
                    <Card.Header>{speaker.name}</Card.Header>
                    <Card.Meta>
                        <span className="date" dangerouslySetInnerHTML={{__html: speaker.title}} />
                    </Card.Meta>
                </Card.Content>
                {(speaker.github || speaker.twitter) && (
                    <Card.Content extra textAlign="center">
                        {speaker.github && (
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`https://www.github.com/${speaker.github}`}>
                                <Icon size="large" name="github" />
                            </a>
                        )}
                        {speaker.twitter && (
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`https://www.twitter.com/${speaker.twitter}`}>
                                <Icon size="large" name="twitter" />
                            </a>
                        )}
                    </Card.Content>
                )}
            </Card>
        );
    
};

export default Speaker;
