import React from 'react';
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';
import { withRouter } from 'react-router-dom';

const ResponsiveContainer = ({ config, location, children }) => {
    const isHome = location.pathname === '/';
    return (
        <>
            <DesktopContainer config={config} isHome={isHome}>
                {children}
            </DesktopContainer>
            <MobileContainer config={config} isHome={isHome}>
                {children}
            </MobileContainer>
        </>
    );
};

export default withRouter(ResponsiveContainer);
