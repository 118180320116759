import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Container, List, Header, Grid, Divider, Button } from 'semantic-ui-react';
import MailListForm from '../common-page/MailListForm';
const Footer = ({ config }) => {
    const { links } = config;
    const { speakers, sponsors, schedule, venue, cfp, about, conduct, tickets, soldOut } = config.site.available;
    const items = { speakers, schedule, venue, sponsors, about, conduct };
    return (
        <Segment inverted vertical style={{ marginTop: 80, padding: '3em 0em' }}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Header inverted as="h4" content="Sitemap" />
                            <List link inverted>
                                <List.Item as={Link} to="/">
                                    Home
                                </List.Item>
                                {Object.keys(items).map((key, index) => {
                                    if (items[key]) {
                                        return (
                                            <List.Item as={Link} to={`/${key}`} key={key} className="footer-link">
                                                {key}
                                            </List.Item>
                                        );
                                    }
                                    return null;
                                })}
                                <List.Item as={Link} to="/conduct">
                                    Conduct
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Header inverted as="h4" content="Social" />
                            <List link inverted>
                                <List.Item
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.youtube.com/channel/UCBoSWHAAhkqrCBsTAeS5cCA">
                                    YouTube
                                </List.Item>
                                <List.Item
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://twitter.com/reactloop">
                                    Twitter
                                </List.Item>
                                <List.Item
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/company/react-loop">
                                    LinkedIn
                                </List.Item>
                                <List.Item
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/reactloop/">
                                    Instagram
                                </List.Item>
                                <List.Item
                                    as="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/reactloop">
                                    Facebook
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {tickets && !soldOut ? (
                                <div>
                                    <Header inverted as="h4" content="Tickets Now on Sale" />
                                    <Button
                                        style={{ letterSpacing: '0.03em' }}
                                        rel="noopener noreferrer"
                                        as="a"
                                        href={links.TICKET_SALES}
                                        target="_blank"
                                        positive
                                        size="tiny">
                                        Get Your Ticket
                                    </Button>
                                </div>
                            ) : (
                                <Header inverted as="h4" content="Tickets are now sold out" />
                            )}
                            {cfp && (
                                <>
                                    <Header inverted as="h4" content="Call for Presenters" />
                                    <Button
                                        style={{ letterSpacing: '0.03em' }}
                                        rel="noopener noreferrer"
                                        as="a"
                                        href={links.PROPOSAL_FORM}
                                        target="_blank"
                                        primary
                                        size="tiny">
                                        Submit a Presentation
                                    </Button>
                                </>
                            )}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header as="h4" inverted>
                                React Loop - June 21, 2019 @ Venue SIX10
                            </Header>
                            <div id="mc_embed_signup">
                                <p>Subscribe for Updates</p>
                                <MailListForm />
                            </div>
                            <div style={{ marginTop: 25 }}>
                                <a style={{ color: '#fff' }} href="mailto:info@reactloop.com">
                                    info@reactloop.com
                                </a>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <div style={{ width: '100%', marginTop: 50 }}>
                <Divider
                    as="a"
                    rel="noopener noreferrer"
                    href="http://www.eventloopllc.com"
                    target="_blank"
                    className="header"
                    horizontal
                    style={{ color: '#ccc', fontSize: '1em' }}>
                    © 2019 Event Loop LLC, All Rights Reserved.
                </Divider>
            </div>
        </Segment>
    );
};

export default Footer;
