import React from 'react';
import { Input } from 'semantic-ui-react';
const MailListForm = () => (
    <form
        action="https://reactloop.us19.list-manage.com/subscribe/post?u=21374a0d875c37cc33973792f&amp;id=0ad00bb6b1"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate  mw7 center pa4 br2-ns ba b--black-10"
        target="_blank"
        noValidate>
        <div id="mc_embed_signup_scroll" className="cf">
            <Input name="EMAIL" className="rf6" id="mce-EMAIL" action="Get in the Loop" placeholder="you@email.com" />
            <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                <div className="response" id="mce-success-response" style={{ display: 'none' }} />
            </div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                <input type="text" name="b_21374a0d875c37cc33973792f_0ad00bb6b1" tabIndex="-1" defaultValue="" />
            </div>
        </div>
    </form>
);

export default MailListForm;
