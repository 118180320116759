import React from 'react';
import { Container, Divider, Header } from 'semantic-ui-react';
import PageHeader from '../common-page/PageHeader';
import YouTube from 'react-youtube';

const Video = ({ config }) => {
    const { speakers } = config;
    const videos = Object.keys(speakers).map(s => ({
        id: speakers[s].video,
        title: speakers[s].talk.title,
        name: speakers[s].name
    }));
    return (
        <>
            <PageHeader className="about" headline="React Loop Videos" config={config} />
            <Container>
                <Header as="h2">React Loop 2019 Videos</Header>
                <div style={{ textAlign: 'center' }}>
                    {videos.map(({ id, title, name }) => (
                        <div key={id}>
                            <Divider />
                            <h3>
                                {title}, by {name}
                            </h3>
                            <YouTube videoId={id} />
                        </div>
                    ))}
                </div>
            </Container>
        </>
    );
};

export default Video;
