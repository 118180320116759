import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Header, Card } from 'semantic-ui-react';
import PageHeader from '../common-page/PageHeader';
import shuffle from '../../utils/shuffle';
import Member from './Member';

const About = ({ config }) => {
    const { site } = config;
    if (!site.available.about) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <PageHeader
                className="about"
                headline="React Loop"
                subline="For developers, by developers."
                config={config}
            />
            <Container style={{ paddingBottom: 100 }}>
                <Header as="h2">What is React Loop?</Header>
                <p>
                    React Loop is a one day single track conference for developers using React, React Native, and the
                    related tools and libraries in the ever-growing React ecosystem.
                </p>
                <Header as="h2">What kind of topics are covered?</Header>
                <p>
                    We include content for seasoned React users as well as those new to the community. We love the
                    cutting edge, but we also appreciate solid software fundamentals.&nbsp;&nbsp;You can expect to hear
                    about the latest features of React, React being used in new and interesting environments, techniques
                    for building and managing React projects, optimizations, testing strategies, helpful libraries, and
                    tales of learning and inspiration.
                </p>
                <Header as="h2">What is the schedule like?</Header>
                <p>
                    We believe free time to network and to share what you're working on and learning with other
                    developers is an important part of a great conference experience so we plan plenty of break
                    time.&nbsp;&nbsp;We don't do long talks.&nbsp;&nbsp;30 minutes is the longest we allow.
                </p>
                <Header as="h2">Organizers</Header>
                <Card.Group>
                    {shuffle(config.team).map((m, i) => (
                        <Member key={i} member={m} />
                    ))}
                </Card.Group>
            </Container>
        </>
    );
};

export default About;
