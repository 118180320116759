import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Footer from './common-page/Footer';
import ResponsiveContainer from './containers/ResponsiveContainer';
import Home from './home/Home';
import Conduct from './conduct/Conduct';
import Speakers from './speakers/Speakers';
import Schedule from './schedule/Schedule';
import Sponsors from './sponsors/Sponsors';
import Venue from './venue/Venue';
import About from './about/About';
import Videos from './videos/Videos';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

class App extends React.Component {
    render() {
        const { config } = this.props;
        const renderRoute = (Component, props) => <Component config={config} {...props} />;
        return (
            <Router>
                <ResponsiveContainer config={config}>
                    <Route exact path="/" render={props => renderRoute(Home, props)} />
                    <Route exact path="/videos" render={props => renderRoute(Videos, props)} />
                    <Route exact path="/conduct" render={props => renderRoute(Conduct, props)} />
                    <Route exact path="/speakers" render={props => renderRoute(Speakers, props)} />
                    <Route exact path="/schedule" render={props => renderRoute(Schedule, props)} />
                    <Route exact path="/sponsors" render={props => renderRoute(Sponsors, props)} />
                    <Route exact path="/venue" render={props => renderRoute(Venue, props)} />
                    <Route exact path="/about" render={props => renderRoute(About, props)} />
                    <Footer config={config} />
                </ResponsiveContainer>
            </Router>
        );
    }
}

export default App;
