import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

const ScheduleSummary = ({ cfp, links }) => (
    <div>
        <h1>Schedule</h1>
        <p>We will post the full schedule once all proposals have been submitted and reviewed. Stay tuned!</p>
        {cfp && (
            <>
                <p>
                    Our{' '}
                    <a href={links.PROPOSAL_FORM} target="_blank" rel="noopener noreferrer">
                        Call for Presenters
                    </a>{' '}
                    is now open. We have some great keynote speakers, but we're hoping to hear from <u>you</u> and that
                    you'll join us by submitting your talk in our open call for presenters. Hit us up at{' '}
                    <a href="mailto:cfp@reactloop.com">cfp@reactloop.com</a> if you have questions, need guidance, or
                    just want to shoot the breeze about your ideas. You can submit as many proposals as you would like.
                    <br />
                </p>
                <Divider />
                <a style={{ fontSize: 20 }} href={links.PROPOSAL_FORM} target="_blank" rel="noopener noreferrer">
                    Click here to submit a presentation
                </a>
                <Divider />
            </>
        )}
        <ul style={{ fontSize: 20, lineHeight: '1.5' }}>
            <li>Registration at the conference will open at 7:30 am on June 21st.</li>
            <li>A light breakfast will be provided starting at 8:00 am.</li>
            <li>Conference presentations will begin at 9:00 am</li>
            <li>Snacks and beverages will be provided throughout the day and during breaks.</li>
            <li>
                We'll be releasing more details about the schedule including lunch and a post-conference event as we
                nail down those details.
            </li>
        </ul>
    </div>
);

ScheduleSummary.propTypes = {
    cfp: PropTypes.bool,
    links: PropTypes.object
};

export default ScheduleSummary;
