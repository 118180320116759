import React from 'react';
import { Redirect } from 'react-router-dom';
import PageHeader from '../common-page/PageHeader';
import { Container } from 'semantic-ui-react';
import ScheduleSummary from './ScheduleSummary';
import ScheduleFull from './ScheduleFull';
import ScheduleTalks from './ScheduleTalks';

class Schedule extends React.Component {
    state = { day: 0 };
    render() {
        const { config } = this.props;
        const { schedule, speakers, site, links } = config;
        if (!site.available.schedule) {
            return <Redirect to="/" />;
        }
        return (
            <>
                <PageHeader
                    className="schedule"
                    headline="The Schedule"
                    subline="What, when, where, and who."
                    cta="Check out the videos"
                    href="/videos"
                    localLink
                    config={config}
                />
                <Container>
                    {site.available.scheduleSummaryOnly ? (
                        <ScheduleSummary cfp={site.available.cfp} links={links} />
                    ) : site.available.scheduleTalksOnly ? (
                        <ScheduleTalks speakers={speakers} />
                    ) : (
                        <ScheduleFull schedule={schedule} speakers={speakers} />
                    )}
                </Container>
            </>
        );
    }
}

export default Schedule;
