import React from 'react';
import { Redirect } from 'react-router-dom';
import PageHeader from '../common-page/PageHeader';
import { Container, Header, Image, Grid, Segment, Button, Divider } from 'semantic-ui-react';
import map from './venue-map.jpg';

const Venue = ({ config }) => {
    const { site, links } = config;
    if (!site.available.venue) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <PageHeader
                className="venue"
                headline="Where it all goes down"
                subline="610 S. Michigan Ave, Chicago IL"
                config={config}
            />
            <Segment style={{ border: 0 }} vertical>
                <Grid container stackable verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as="h3" style={{ fontSize: '2em' }}>
                                Venue SIX10, Feinberg Theater
                            </Header>
                            <p style={{ fontSize: '1.33em' }}>610 S. Michigan Ave, Chicago IL</p>
                            <Button as="a" href={links.VENUE_DIRECTIONS} size="huge">
                                Get Directions
                            </Button>
                        </Grid.Column>
                        <Grid.Column floated="right" width={6}>
                            <Image src={map} alt="Venue SIX10 location on Google Maps" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment style={{ border: 0 }} vertical>
                <Container text>
                    <Divider
                        as="h4"
                        className="header"
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}>
                        Public Transportation
                    </Divider>
                    <p>
                        <b>Red Line</b>: The Red Line Harrison stop is located two blocks west of the venue.
                    </p>
                    <p>
                        <b>Blue Line</b>: From the LaSalle stop, walk east on Congress Pkwy and take a right on Michigan
                        Ave. The venue is about a block south on Michigan Ave.
                    </p>
                    <Divider
                        as="h4"
                        className="header"
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}>
                        Driving
                    </Divider>
                    <b>From the North</b>
                    <p>
                        Take I-90/94 south to Congress exit. Go east to State Street, south to Harrison, east to
                        Michigan Avenue. South on Michigan Avenue. Venue SIX10 is at 610 S. Michigan Avenue between
                        Harrison and Balbo.
                    </p>
                    <b>From the South</b>
                    <p>
                        Take I-90/94 north to Congress exit. Go east to State Street, south to Harrison, east to
                        Michigan Avenue. South on Michigan Avenue. Venue SIX10 is at 610 S. Michigan Avenue between
                        Harrison and Balbo.
                    </p>
                    <b>From the West</b>
                    <p>
                        Take 290 east as it becomes Congress Parkway downtown. Go south on State Street to Harrison,
                        east to Michigan Avenue. South on Michigan Avenue. Venue SIX10 is at 610 S. Michigan Avenue
                        between Harrison and Balbo.
                    </p>
                    <b>From the Southwest</b>
                    <p>
                        Take I-55 east to I-90/94 north and exit at Congress Parkway. Go east to State Street, south to
                        Harrison, east to Michigan Avenue. South on Michigan Avenue. Venue SIX10 is at 610 S. Michigan
                        Avenue between Harrison and Balbo.
                    </p>
                    <Divider
                        as="h4"
                        className="header"
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}>
                        Discounted Parking $8/12 hrs.
                    </Divider>
                    <p>
                        Discounted parking is available with Venue SIX10 validation at Grant Park South Garage, also
                        known as Millennium Park South Garage, underground garage located on Michigan Ave. between Van
                        Buren and Adams Streets (325 S. Michigan Ave).
                    </p>
                    <p>Please Note: Venue SIX10 does not manage this lot and parking rates are subject to change.</p>
                    <Divider
                        as="h4"
                        className="header"
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}>
                        Accessibility
                    </Divider>
                    <p>
                        <b>Inside the Theater</b>: The Feinberg Theater is equipped with wheelchair-accessible seating
                        locations.
                    </p>
                </Container>
            </Segment>
        </>
    );
};

export default Venue;
