import site from './site.json';
import speakers from './speakers.json';
import sponsors from './sponsors.json';
import one from './schedule-day-one.json';
import team from './team.json';
import links from './links.json';
import dates from './dates.json';
import tickets from './tickets.json';
import venue from './venue.json';

export default {
    site,
    speakers,
    sponsors,
    schedule: [one],
    links,
    dates,
    tickets,
    venue,
    team
};
