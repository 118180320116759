import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import logo from './logo.png';
import { Container, Menu, Responsive, Image } from 'semantic-ui-react';

class DesktopContainer extends React.Component {
    state = { avtiveItem: null };
    navClick = (e, { name }) => this.setState({ activeItem: name });
    render() {
        const { activeItem } = this.state;
        const { config, children } = this.props;
        const { TICKET_SALES } = config.links;
        const { speakers, sponsors, tickets, schedule, venue, videos, about, soldOut } = config.site.available;
        const items = { videos, speakers, schedule, venue, sponsors, about };

        return (
            <ScrollToTop>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Menu fixed="top">
                        <Container fluid>
                            <Menu.Item
                                onClick={this.navClick}
                                name="home"
                                as={Link}
                                to="/"
                                style={{ border: 0 }}
                                className="logo borderless">
                                <Image
                                    src={logo}
                                    alt="React Loop - The First Chicago ReactJS Conference"
                                    size="small"
                                />
                            </Menu.Item>

                            <Menu.Menu position="right">
                                {tickets && !soldOut && (
                                    <Menu.Item as="a" target="_blank" rel="noopener noreferrer" href={TICKET_SALES}>
                                        Buy Tickets
                                    </Menu.Item>
                                )}
                                {Object.keys(items).map((key, index) => {
                                    if (items[key]) {
                                        return (
                                            <Menu.Item
                                                onClick={this.navClick}
                                                name={key}
                                                key={key}
                                                as={NavLink}
                                                to={`/${key}`}>
                                                {key}
                                            </Menu.Item>
                                        );
                                    }
                                    return null;
                                })}
                                <Menu.Item
                                    onClick={this.navClick}
                                    active={activeItem === 'conduct'}
                                    name="conduct"
                                    as={NavLink}
                                    to="/conduct">
                                    Conduct
                                </Menu.Item>
                            </Menu.Menu>
                        </Container>
                    </Menu>
                    <Container fluid style={{ marginTop: 72 }}>
                        {children}
                    </Container>
                </Responsive>
            </ScrollToTop>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
    config: PropTypes.object,
    isHome: PropTypes.bool
};

export default withRouter(DesktopContainer);
