import React from 'react';
import { Redirect } from 'react-router-dom';
import Speaker from './Speaker';
import PageHeader from '../common-page/PageHeader';
import shuffle from '../../utils/shuffle';
import { Container, Card } from 'semantic-ui-react';

const Speakers = ({ config }) => {
    const { speakers, site } = config;
    if (!site.available.speakers) {
        return <Redirect to="/" />;
    }
    return (
        <>
            <PageHeader
                className="speakers"
                headline="The Speakers"
                subline="We're trying to be cool, but -- Dang! Look at this awesome group!"
                cta="Check out the talks"
                href={'/schedule'}
                localLink
                config={config}
            />
            <Container className="speakers-cards">
                <Card.Group>
                    {shuffle(Object.keys(speakers)).map(speaker => (
                        <Speaker key={speaker} url={speaker} speaker={speakers[speaker]} />
                    ))}
                </Card.Group>
            </Container>
        </>
    );
};

export default Speakers;
