import React from 'react';
import { Link } from 'react-router-dom';
import TicketGroup from './TicketGroup';
import Speaker from '../speakers/Speaker';
import PageHeader from '../common-page/PageHeader';
import shuffle from '../../utils/shuffle';
import { Container, Header, Divider, Card, Button, Icon } from 'semantic-ui-react';

const hotels = [
    {
        name: 'The Blackstone',
        address: '636 S Michigan Avenue, Chicago, IL 60605',
        distance: '246ft from the venue',
        url: 'https://www.theblackstonehotel.com/'
    },
    {
        name: 'Congress Plaza',
        address: '520 S Michigan Ave, Chicago, IL 60605',
        distance: '500ft from the venue',
        url: 'https://www.congressplazahotel.com/'
    },
    {
        name: 'Hilton Chicago',
        address: '720 S Michigan Ave, Chicago, IL 60605',
        distance: '1 block from the venue',
        note: 'Speaker Hotel',
        url: 'https://www3.hilton.com/en/hotels/illinois/hilton-chicago-CHICHHH/index.html'
    },
    {
        name: 'Travelodge by Wyndham',
        address: '65 E Harrison St, Chicago, IL 60605',
        distance: '433ft from the venue',
        url: 'https://www.wyndhamhotels.com/travelodge/chicago-illinois/travelodge-hotel-downtown-chicago/overview'
    },
    {
        name: 'Hotel Essex',
        address: '800 S Michigan Ave, Chicago, IL 60605',
        distance: '2 blocks from the venue',
        url: 'https://www.hotelessexchicago.com/'
    },
    {
        name: 'Best Western',
        address: '1100 S Michigan Ave, Chicago, IL 60605',
        distance: '5 blocks from the venue',
        url: 'https://www.bwgrantparkhotel.com/'
    }
];

const Home = ({ config }) => {
    const { links, speakers, site, dates, tickets } = config;
    return (
        <>
            <PageHeader
                className="home"
                headline="React Loop - Chicago's First React Conference"
                cta="Call for Presenters"
                href={site.available.cfp ? links.PROPOSAL_FORM : null}
                subline="June 21, 2019 @ Venue SIX10"
                config={config}
            />
            <Container style={{ padding: '50px 0', position: 'relative', top: -18 }}>
                <Header as="h3" style={{ lineHeight: 1.8, fontSize: '1.7em', textAlign: 'center', fontWeight: 500 }}>
                    React Loop is a single day ReactJS conference bringing together a diverse and inclusive group of
                    React enthusiasts from all backgrounds and levels of experience. Speakers will cover topics such as
                    React, React Native, and the related tools in the ever-growing React ecosystem.
                </Header>
            </Container>

            {site.available.speakers && (
                <>
                    <Divider />
                    <Container style={{ paddingTop: 25 }} className="speakers-cards">
                        <Header as="h2" content="Featured Speakers" />
                        <Card.Group>
                            {shuffle(Object.keys(speakers)).map(speaker => {
                                const s = speakers[speaker];
                                return (
                                    s.featured && (
                                        <Speaker
                                            featured
                                            key={speaker}
                                            speaker={s}
                                            url={site.available.schedule ? speaker : null}
                                        />
                                    )
                                );
                            })}
                        </Card.Group>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '25px 0 0 0',
                                margin: '0 auto'
                            }}>
                            <Button icon size="medium" primary as={Link} to="/speakers">
                                See all of the speakers here
                                <Icon name="right arrow" />
                            </Button>
                        </div>
                    </Container>
                </>
            )}
            {site.available.ticketInfo && (
                <>
                    <Divider />
                    <Container style={{ padding: '50px 0' }} className="home-tickets-cards">
                        <Header as="h2" content="Tickets" />
                        <Card.Group>
                            <TicketGroup
                                link={config.links.TICKET_SALES}
                                buy={site.available.tickets}
                                ticketGroup={tickets[0]}
                                date={dates.TICKET_RELEASE}
                            />
                            <TicketGroup
                                link={config.links.TICKET_SALES}
                                buy={site.available.tickets}
                                ticketGroup={tickets[1]}
                                date={dates.TICKET_RELEASE}
                            />
                            <TicketGroup
                                link={config.links.TICKET_SALES}
                                buy={true}
                                ticketGroup={tickets[2]}
                                date={'05/15/2019'}
                            />
                        </Card.Group>
                    </Container>
                </>
            )}
            <Divider />
            <Container style={{ padding: '50px 0' }}>
                <Header as="h2" content="Diversity Tickets" />
                <p>
                    React Loop is partnering with the Travis Foundation to offer a set of tickets through their
                    diversitytickets.org program to members of underrepresented groups in tech at no cost. The
                    application process for the tickets is now closed.
                </p>
            </Container>
            <Divider />
            <Container style={{ padding: '50px 0' }}>
                <Header as="h2" content="Venue" />
                <p>
                    React Loop 2019 will take place at Venue SIX10 located at 610 S. Michigan Ave, Chicago IL.{' '}
                    <Link to="/venue">Learn more</Link>
                </p>
            </Container>
            <Divider />
            <Container style={{ padding: '50px 0' }}>
                <Header as="h2" content="Accommodations" />
                <p>There are no official conference accommodations, but there are several hotels nearby, including:</p>
                <Card.Group>
                    {hotels.map((h, i) => (
                        <Card key={i} as="a" href={h.url} rel="noopener noreferrer" target="_blank">
                            <Card.Content>
                                <Card.Header>
                                    {h.name} {h.note && <small>({h.note})</small>}
                                </Card.Header>
                                <Card.Meta>{h.address}</Card.Meta>
                                <Card.Description>{h.distance}</Card.Description>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
                <br />
                <p style={{ fontSize: 14 }}>There are plenty more within walking distance of the venue.</p>
            </Container>
        </>
    );
};

export default Home;
