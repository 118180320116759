import React from 'react';
import PropTypes from 'prop-types';
import { Item, Divider } from 'semantic-ui-react';
import shuffle from '../../utils/shuffle';
const imagePath = process.env.PUBLIC_URL + '/assets/speaker_images/';

const ScheduleTalks = ({ speakers }) => (
    <div>
        <ul style={{ fontSize: 16, lineHeight: '1.5' }}>
            <li>Registration at the conference will open at 7:30 am on June 21st.</li>
            <li>A light breakfast will be provided starting at 8:00 am.</li>
            <li>Conference presentations will begin at 9:00 am</li>
            <li>Snacks and beverages will be provided throughout the day and during breaks.</li>
            <li>
                We'll be releasing more details about the schedule including lunch and a post-conference event as we
                nail down those details.
            </li>
        </ul>
        <p>
            While we're still working out the final schedule check out all of the awesome presentations you'll see at
            React Loop 2019!
        </p>
        <Divider />
        <Item.Group divided>
            <Item>
                <Item />
                <Item.Image size="tiny" src={`${imagePath}${speakers.mjackson.avatar}`} />
                <Item.Content>
                    <Item.Meta>{speakers.mjackson.name}</Item.Meta>
                    <Item.Header>{speakers.mjackson.talk.title}</Item.Header>
                    <Item.Description style={{ fontSize: '1.1em', lineHeight: 1.6 }}>
                        {speakers.mjackson.talk.description}
                    </Item.Description>
                </Item.Content>
            </Item>
            {shuffle(Object.keys(speakers))
                .filter(k => k !== 'mjackson' && k !== 'tylermcginnis')
                .map((key, index) => {
                    const { avatar, talk, name } = speakers[key];
                    return (
                        <Item key={index}>
                            <Item />
                            <Item.Image size="tiny" src={`${imagePath}${avatar}`} />
                            <Item.Content>
                                <Item.Meta>{name}</Item.Meta>
                                <Item.Header>{talk.title}</Item.Header>
                                <Item.Description style={{ fontSize: '1.1em', lineHeight: 1.6 }}>
                                    {talk.description}
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    );
                })}
            <Item>
                <Item />
                <Item.Image size="tiny" src={`${imagePath}${speakers.tylermcginnis.avatar}`} />
                <Item.Content>
                    <Item.Meta>{speakers.tylermcginnis.name}</Item.Meta>
                    <Item.Header>{speakers.tylermcginnis.talk.title}</Item.Header>
                    <Item.Description style={{ fontSize: '1.1em', lineHeight: 1.6 }}>
                        {speakers.tylermcginnis.talk.description}
                    </Item.Description>
                </Item.Content>
            </Item>
        </Item.Group>
    </div>
);

ScheduleTalks.propTypes = {
    speakers: PropTypes.object
};

export default ScheduleTalks;
