import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'semantic-ui-react';
import moment from 'moment';

const imagePath = process.env.PUBLIC_URL + '/assets/speaker_images/';

const ScheduleFull = ({ schedule, speakers }) => (
    <div>
        <Item.Group divided>
            {schedule[0].map((item, index) => {
                if (item.type === 'presentation') {
                    const { avatar, talk, name, video } = speakers[item.presenter];
                    return (
                        <Item key={index}>
                            <Item />
                            <Item.Image size="tiny" src={`${imagePath}${avatar}`} />
                            <Item.Content>
                                <Item.Meta>
                                    {moment(item.time, 'HH:mm').format('h:mm a')} - {name}
                                </Item.Meta>
                                <Item.Header>{talk.title}</Item.Header>
                                <Item.Description style={{ fontSize: '1.1em' }}>
                                    {talk.description}
                                    {video && (
                                        <a style={{ marginLeft: 10 }} href={`https://youtu.be/${video}`}>
                                            Video
                                        </a>
                                    )}
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    );
                }

                return (
                    <Item key={index}>
                        <Item.Content>
                            <Item.Meta>{moment(item.time, 'HH:mm').format('h:mm a')}</Item.Meta>
                            <Item.Header>{item.title}</Item.Header>
                            <Item.Description>{item.description}</Item.Description>
                        </Item.Content>
                    </Item>
                );
            })}
        </Item.Group>
    </div>
);

ScheduleFull.propTypes = {
    schedule: PropTypes.array,
    speakers: PropTypes.object
};

export default ScheduleFull;
