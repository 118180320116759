import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';
import MailListForm from './MailListForm';
import { Spring, config as springConfig } from 'react-spring';

const rand = (n, min = 1) => Math.floor(Math.random() * (n - min + 1)) + min;

const PageHeader = ({ className, headline, subline, cta, href, config, localLink }) => {
    const hx = rand(1000, 200) * -1;
    const sx = rand(1000, 200) * -1;
    const immediate = document.body.clientWidth < 768;
    return (
        <div className={`loop-header ${className}`}>
            <div className="loop-header-container">
                <Spring immediate={immediate} config={springConfig.wobbly} from={{ x: hx }} to={{ x: 0 }}>
                    {({ x }) => (
                        <div className="block-header" style={{ marginLeft: x }}>
                            <Header as="h1" content={headline} />
                        </div>
                    )}
                </Spring>
                {subline && (
                    <Spring immediate={immediate} config={springConfig.gentle} from={{ x: sx }} to={{ x: 0 }}>
                        {({ x }) => (
                            <div className="block-header" style={{ marginLeft: x }}>
                                <Header as="h2" content={subline} style={{ color: '#ffffff' }} />
                            </div>
                        )}
                    </Spring>
                )}
                {config.site.available.tickets && !config.site.available.soldOut && (
                    <Button
                        style={{ fontWeight: 300, marginBottom: 10 }}
                        as="a"
                        color="green"
                        href={config.links.TICKET_SALES}
                        target="_blank"
                        size="huge">
                        Buy Your Ticket
                    </Button>
                )}
                {href &&
                    cta &&
                    (localLink ? (
                        <Button style={{ fontWeight: 300, marginBottom: 10 }} as={Link} to={href} primary size="huge">
                            {cta}
                        </Button>
                    ) : (
                        <Button
                            style={{ fontWeight: 300, marginBottom: 10 }}
                            as="a"
                            href={href}
                            target="_blank"
                            primary
                            size="huge">
                            {cta}
                        </Button>
                    ))}
            </div>
            <div className="page-header-mail-form" style={{ backgroundColor: '#016994' }}>
                <div className="page-header-mail-form-label">Subscribe for React Loop updates and news: </div>
                <MailListForm />
            </div>
        </div>
    );
};

export default PageHeader;
